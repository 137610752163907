import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

export class ReportEmployeeRequest {
    format: string;
    date: Date;
    languageId: string;
    employeeId: number;
    hollenCompanyCode: string;
}

@Injectable()
export class ReportEmployeeService
    extends HttpService<any, any, any>
{
    objectKey = "op.employee";
    format = "xlsx";

    constructor(http: HttpClient) {
        super(http, "/api/operation/reportemployee/");
    }

    getReports(request: ReportEmployeeRequest) {
        return this.http.get<app.operation.EmployeeReportViewModel>(this.url, { params: this.getParameters(request) });
    }

    getReportParameters(reportId: number) {
        return this.http.get(this.url + reportId + "/parameters");
    }

    getReportUrl(report: app.sys.ReportDetailViewModel, request: ReportEmployeeRequest, parameters?: any[]) {
        let requestParams = this.getParameters(request);

        let params = [];

        if (parameters) {
            for (let item of parameters) {
                params[item.field] = item.value;
            }

            let reportParams = this.getParameters(params);

            for (let key of reportParams.keys()) {
                requestParams = requestParams.append(key, reportParams.get(key));
            }
        }

        let format = report.format ? report.format : this.format;
        let reportLink = this.url + report.reportId + "/export?format=" + format + "&" + requestParams.toString();
        return reportLink;
    }
}
