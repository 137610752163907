import { Observable, of } from "rxjs";
import { Component, OnInit, EventEmitter } from "@angular/core";
import { NgbModal, NgbActiveModal, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";

import { ReportEmployeeService, ReportEmployeeRequest } from "./report-employee.service";
import { ProjectTaskService } from "./../project-task/project-task.service";
import { WorkPlaceService } from "./../../organization/work-place/work-place.service";
import { AppService } from "../../app.service";
import { filterAndDebounce } from "../../core/utils";
import { switchMap, distinctUntilChanged, debounceTime, tap, finalize } from "rxjs/operators";

@Component({
    selector: "op-report-employee",
    styleUrls: ["./report-employee.component.scss"],
    templateUrl: "./report-employee.component.html"
})
export class ReportEmployeeComponent implements OnInit {
    objectKey: string;
    queryParams = new ReportEmployeeRequest();
    reports: app.sys.ReportViewModel[];
    languages: af.SelectViewModel[];
    hollenCompanies: app.organization.HollenCompanySelectListViewModel[];
    selectedReport = 0;
    currentUser: app.UserViewModel;
    isLoading: boolean;
    employeeId: number;

    constructor(private route: ActivatedRoute,
        private reportService: ReportEmployeeService,
        private appService: AppService) {
        this.currentUser = appService.homeViewModel.user;
        this.objectKey = reportService.objectKey;
        this.resetFilter();
    }

    ngOnInit() {
        this.employeeId = +this.route.parent.snapshot.params["employeeId"];
        this.queryParams.employeeId = this.employeeId;

        this.isLoading = true;

        this.loadReports();
    }

    loadReports() {
        this.isLoading = true;
        this.reportService.getReports(this.queryParams).subscribe(result => {
            this.reports = result.items;
            this.hollenCompanies = result.hollenCompanies;
            this.isLoading = false;
        });
    }

    updateParams() {
        this.queryParams = Object.assign({}, this.queryParams);
    }

    selectReport(type: any) {
        this.selectedReport = type;
    }

    isVisible(type: any) {
        return (this.selectedReport === 0 || this.selectedReport === type);
    }

    onLanguageChange() {
        if (this.selectedReport > 0) {
            this.selectedReport = 0;
        }
        this.updateParams();
        this.loadReports();
    }

    resetFilter() {
        this.queryParams.employeeId = this.employeeId;
        this.queryParams.languageId = this.currentUser.languageByCountry;
        this.queryParams.date = moment().toDate();
    }

    cancelFilter() {
        this.selectedReport = 0;
    }
}
