
<div class="form" *ngIf="showProjectTaskHeader && projectTask">
    <div class="form-horizontal">
        <div class="form-body">
            <h4 class="form-section project-task">
                <a *ngIf="projectTask.previousProjectTaskId" class="fa fa-chevron-left" [routerLink]="['/operation/projectTask', projectTask.previousProjectTaskId, 'file']"></a>
                {{ 'projecttask.panel.project task' | translate }} > <af-title [title]="projectTask.projectTaskNumber"></af-title> > {{ projectTask.startDate | afDate: 'MM / YYYY' }}
                <a *ngIf="projectTask.nextProjectTaskId" class="fa fa-chevron-right" [routerLink]="['/operation/projectTask', projectTask.nextProjectTaskId, 'file']"></a>
            </h4>
        </div>
    </div>
</div>

<div class="row" *ngIf="afTable && (isFilter || isUpload)">
    <div class="col-md-6">
        <af-page-filter *ngIf="isFilter" [control]="afTable" [loading]="isLoading" [params]="queryParams" (filter)="loadData()" (reset)="onReset()" (clear)="onClear()">
            <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="queryParams.searchTerm" placeholder="{{ 'filter.keyword' | translate }}" />
            </div>
        </af-page-filter>
    </div>
    <div class="col-md-6">
        <af-page-bar>
            <a *ngIf="isSharepointEnabled" class="btn-info btn" [class.disabled-all]="!sharePointUrl" target="_blank" [href]="sharePointUrl">
                <af-spinner type="circle" [isInline]="true" *ngIf="isLoadingSharePoint" [loading]="true"></af-spinner>
                SharePoint
            </a>
            <af-file-upload name="FileName" [simple]="true" [multiple]="true" styleClass="btn btn-success" *afHasPerm="{obj:objectKey, perm: 'Insert', if: isUpload}" (success)="onSuccessUpload($event)" [options]="uploadOptions">
                {{ 'hollen.clientapp.src.app.operation.project-task.project-task-file.component.upload file' | translate }}  <i class="fa fa-plus"></i>
            </af-file-upload>
            <af-help *ngIf="isUpload" class="help-static" style="margin-left: 3px;" helpid="sharePoint.fileUpload"></af-help>
        </af-page-bar>
    </div>
</div>

<af-table  #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()" [paginator]="paginator" noRecordsFoundText="{{ 'hollen.clientapp.src.app.operation.project-task.project-task-file.component.no files found' | translate }}">
    <af-column field="fileName" sortable="fileName" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.file' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <i *ngIf="item.wasUpdatedAfterPapersCompletition" class="fa fa-exclamation-triangle" style="color:red"></i>
            <i class="fa fa-cog" *ngIf="item.isGenerated"></i>
            <a [href]="sanitize(item.fileUrl)" [target]="target(item)">{{ item.fileName }}</a>
        </ng-template>
    </af-column>
    <af-column field="createdUtc" sortable="createdUtc" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.created' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.createdUtc | afDate : 'L LTS' : true }}
        </ng-template>
    </af-column>
    <af-column field="createdBy" sortable="createdBy" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.author' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.createdBy }}
        </ng-template>
    </af-column>
    <af-column field="lastUpdatedUtc" sortable="lastUpdatedUtc" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.edited' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.lastUpdatedUtc | afDate : 'L LTS' : true }}
        </ng-template>
    </af-column>
    <af-column field="lastUpdatedBy" sortable="lastUpdatedBy" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.editor' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.lastUpdatedBy }}
        </ng-template>
    </af-column>
    <af-column field="documentType" sortable="documentType" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.document type' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.documentType }}
        </ng-template>
    </af-column>
    <af-column field="length" sortable="length" styleClass="text-center" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.size' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.length | afFileSize }}
        </ng-template>
    </af-column>
    <af-column field="fileType" sortable="fileType" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.file type' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.fileType}}
        </ng-template>
    </af-column>
    <af-column field="monthYear" sortable="yearMonth" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.month' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{ item.monthYear }}
        </ng-template>
    </af-column>
    <af-column field="forInv" sortable="forInv" styleClass="text-center" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.for inv.' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="item.forInv" [disabled]="getForInvLocked(item) || item['updating']" (change)="updateItem(item, { forInv: !item.forInv }, false)">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
    </af-column>
    <af-column *ngIf="projectTask?.isMetriq" field="isVisibleForCustomer" sortable="isVisibleForCustomer" styleClass="text-center">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="item.isVisibleForCustomer" [disabled]="item['updating']" (change)="updateItem(item, { isVisibleForCustomer: !item.isVisibleForCustomer }, false)">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
        <ng-template afTemplate>
            {{ 'hollen.clientapp.src.app.operation.project-task.project-task-file.component.visible in mqv' | translate }}         
        </ng-template>
    </af-column>
    <af-column *ngIf="projectTask?.isMetriq" field="isSwpVisible" sortable="isSwpVisible" styleClass="text-center">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="item.isSwpVisible" [disabled]="isRightPrefix(item.documentTypePrefix) || item['updating'] || item.documentTypePrefix === 'WS' || item.documentTypePrefix === 'OR'" (change)="updateItem(item, { isSwpVisible: !item.isSwpVisible })">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
        <ng-template afTemplate>      
                {{ 'hollen.clientapp.src.app.operation.project-task.project-task-file.component.visible in mqi' | translate }}      
        </ng-template>
    </af-column>

    <af-column  *ngIf="projectTask?.isMetriq" field="isMQVSignatureRequired" sortable="isMQVSignatureRequired" styleClass="text-center" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.mqv signature' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="item.isMQVSignatureRequired" [disabled]="isRightPrefix(item.documentTypePrefix) || item['updating'] || item.documentTypePrefix === 'WS' || item.documentTypePrefix === 'OR'" (change)="updateItem(item, { isMQVSignatureRequired: !item.isMQVSignatureRequired })">
                <label class="form-check-label">    {{ item.approvalDate|afDate:'DD.MM.YYYY HH:mm':true}}   </label>
            </div>
        </ng-template>
    </af-column>

    <af-column *ngIf="projectTask?.isMetriq" field="isNotificationRequired" sortable="isNotificationRequired" styleClass="text-center" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.notification required' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="item.isNotificationRequired" [disabled]="item['updating']" (change)="updateItem(item, { isNotificationRequired: !item.isNotificationRequired },false)">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
    </af-column>
    <af-column *ngIf="projectTask?.isMetriq" field="isAccepted" sortable="isAccepted" styleClass="text-center" >  
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="item.isAccepted" [disabled]="item['updating']" (change)="updateItem(item, { isAccepted: !item.isAccepted },false)">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
        <ng-template afTemplate>
            {{ 'hollen.clientapp.src.app.operation.project-task.project-task-file.component.accept and lock mqv' | translate }}
        </ng-template>
    </af-column>
    <af-column *ngIf="projectTask?.isMetriq" field="isActive" sortable="isActive" styleClass="text-center" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.active' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="item.isActive" [disabled]="item['updating']" (change)="updateItem(item, { isActive: !item.isActive },false)">
                <label class="form-check-label"></label>
            </div>
        </ng-template>
    </af-column>
    <af-column  *ngIf="projectTask?.isMetriq" field="lastSyncTime" sortable="lastSyncTime" header="{{ 'hollen.sharepoint.sharepointfileviewmodel.last sent datetime' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <span [class.text-danger]="!item.isMetriqVersionMatching && item.lastSyncTime">
                {{ item.lastSyncTime |  afDate:'DD.MM.YYYY HH:mm' }}
                <ng-container *ngIf="!item.isMetriqVersionMatching && item.lastSyncTime ">
                    <i class="fa fa-refresh" style="color:red"></i>
                </ng-container>
                <ng-container *ngIf="item.isMetriqVersionMatching && item.lastSyncTime">
                    <i class="fa fa-check" style="color:green"></i>
                </ng-container>
            </span>

            <div *ngIf="item.lastSyncTime">
                <button *afHasPerm="{obj:objectKey, perm: 'Update'}" class="btn btn-sm btn-update btn-primary" type="button" (click)="updateItem(item, null, true)">{{ 'hollen.clientapp.src.app.operation.project-task.project-task-file.component.synchronize' | translate }}</button>
            </div>

        </ng-template>
    </af-column>
    <af-column styleClass="text-center">
        <ng-template let-item="rowData" afTemplate>
            <ng-container *ngIf="!(item['forInv'] && getForInvLocked(item)) && !item['approvalDate'] && projectTask && !projectTask.isSharePointFileDeleteDisabled">
                <button afConfirmClick (confirmed)="delete(item);" type="button" class="btn btn-sm btn-delete btn-danger" [disabled]="item['updating']"
                        placement="left"
                        *afHasPerm="{ obj:objectKey, perm: 'Delete', if: !(item['forInv'] && getForInvLocked(item)) && !item['approvalDate'] && projectTask && !projectTask.isSharePointFileDeleteDisabled }"
                        message="{{ 'confirm.are you sure you want to delete this file?' | translate }}"
                        yes="{{ 'confirm.delete.delete' | translate }}"
                        no="{{ 'confirm.delete.cancel' | translate }}">
                    {{ 'btn.delete' | translate }}
                </button>
                </ng-container>

        </ng-template>
        <ng-template afTemplate>
        {{ 'hollen.clientapp.src.app.operation.project-task.project-task-file.component.action' | translate }}
        </ng-template>
    </af-column>
</af-table>
