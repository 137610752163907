import { finalize, switchMap } from "rxjs/operators";
import { forkJoin as observableForkJoin, Observable, of } from "rxjs";
import { Component, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { FormComponent } from "src/app/core";
import { FileUploadOptions } from '../../shared/components/file-upload/file-upload.service';
import { PresentationItemService } from './presentation-item.service';
import { ProjectTaskFileService } from '../project-task/project-task-file.service';
import { filterAndDebounce } from "../../core/utils";
import { ProjectTaskService } from "../project-task/project-task.service";

@Component({
    selector: "op-presentation-item-create",
    templateUrl: "./presentation-item-create.component.html"
})
export class PresentationItemCreateComponent extends FormComponent {
    @Input() firstProjectTaskId: number;
    @Input() isPresentationConfirmationRequired: boolean;
    @Output() inserted = new EventEmitter<number>();

    @ViewChild(NgForm, { static: true }) createForm: NgForm;
    presentationItem = {} as app.operation.ProjectTaskPresentationItemInsertUpdateViewModel;
    objectKey: string;
    uploadOptions: FileUploadOptions;

    constructor(protected router: Router, protected route: ActivatedRoute,
        private presentationItemService: PresentationItemService,
        private projectTaskFileService: ProjectTaskFileService,
        private projectTaskService: ProjectTaskService) {
        super(router);
        this.objectKey = presentationItemService.objectKey;

        this.uploadOptions = new FileUploadOptions();
        this.uploadOptions.url = presentationItemService.getUrl(this.firstProjectTaskId);
    }

    ngOnInit() {  
        this.loadData();
    }

    loadData() {
        this.presentationItem = <app.operation.ProjectTaskPresentationItemInsertUpdateViewModel>{
            components: []
        };
        this.checkRows();
    }

    onStartUpload() {
        if (this.createForm.invalid) {
            return;
        }

        if (this.firstProjectTaskId) {
            this.presentationItem.firstProjectTaskId = this.firstProjectTaskId;
        }

        this.setUploadOptions();
    }

    onUpload() {
        this.isLoading = true;
    }


    setUploadOptions() {
        this.uploadOptions = this.projectTaskFileService.getUploadOptions(this.firstProjectTaskId, false, false,true);
    } 

    onSuccessUpload($event) {
        const response = JSON.parse($event.response);    
        let url = response.url;
        let fileName = url.substring(url.lastIndexOf('/') + 1); 
        this.presentationItem.fileName = fileName;
        this.presentationItem.fileUniqueId = response.uniqueId;
        this.presentationItem.firstProjectTaskId = this.firstProjectTaskId;
        this.isLoading = true;

        if (!this.presentationItem.length) {
            this.presentationItem.length = 1;
        }

        this.presentationItemService.save(this.firstProjectTaskId, this.presentationItem)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => {
                this.createForm.form.markAsPristine();
                this.inserted.emit(response);
                this.isLoading = false;
            });
    }
    
    onErrorUpload() {
        console.log("onErrorUpload");
        this.isLoading = false;
    }

    deleteComponent(id: number, index: number) {
        this.presentationItem.components.splice(index, 1);
        this.checkRows();
    }

    checkValue(items: app.operation.ProjectTaskPartNumberSelectListViewModel[], index: number) {
        if (items[index] !== null && typeof items[index] === "object") {
            return;
        }
        items[index] = <app.operation.ProjectTaskPartNumberSelectListViewModel>{};
    }

    addComponent() {
        if (!this.presentationItem.components) {
            this.presentationItem.components = [];
        }

        this.presentationItem.components.push(<app.operation.ProjectTaskPartNumberSelectListViewModel>{});
    }

    componentFormatter = (component: app.operation.ProjectTaskPartNumberSelectListViewModel) => component.partNumber;

    componentAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.projectTaskService.getComponentSelectList(this.firstProjectTaskId, term)));
    }

    checkRows() {
        let defaultCount = 1;
        if (this.presentationItem.components.length < defaultCount) {
            this.addComponent();
        }
    }

    onPresentationItemChange($event: app.operation.ProjectTaskPartNumberSelectListViewModel, index) {
        if ($event && $event.partNumber) {
            this.presentationItem.components[index] = $event;
        }
    }
}
