import { finalize } from "rxjs/operators";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

//import { PresentationItemService } from "./presentation-item.service";
import { ListQueryParams } from "src/app/models/list-query-params.model";
import { TableBaseComponent } from "src/app/core";
import { PresentationItemService } from './presentation-item.service';
import { ProjectTaskFileService } from '../project-task/project-task-file.service';

export class QueryParams extends ListQueryParams {
    presentationItemTypeId: number = null;
    firstProjectTaskId: number = null;
    projectTaskId: number;
    onlyPresentation: boolean = false;
}

@Component({
    selector: "op-presentation-item-list",
    templateUrl: "./presentation-item-list.component.html"
})

export class PresentationItemListComponent extends TableBaseComponent { //implements OnInit 
    @Input() firstProjectTaskId: number;
    @Output() contentChange = new EventEmitter();
    @Input() content: any;
    queryParams = new QueryParams();
    objectKey: string;
    model: PagedList<app.operation.ProjectTaskPresentationItemListViewModel>;
    modelFiles: PagedList<app.sharepoint.SharePointFileViewModel>;
    //filter = <app.operation.presentationitems.queries.getpresentationitemfilter.ViewModel>{};

    isSharepointEnabled = false;
    isLoadingSharePoint = false;
    sharePointUrl: string;

    constructor(router: Router, private presentationItemService: PresentationItemService, private projectTaskFileService: ProjectTaskFileService) {
        super(router);
        this.objectKey = presentationItemService.objectKey;
        this.queryParams.sortBy = this.sortBy = "recordOrder";
    }

    ngOnInit() {
        super.ngOnInit();

        this.isLoading = true;
        if (this.firstProjectTaskId) {
            this.queryParams.firstProjectTaskId = this.firstProjectTaskId;
        }

        this.loadData();
    }

    loadData(isInsertEmmit:boolean = false) {
        this.isLoading = true;
        this.queryParams.projectTaskId = this.queryParams.firstProjectTaskId;
        this.queryParams.onlyPresentation = true;

        this.presentationItemService
            .query(this.queryParams.firstProjectTaskId, this.queryParams).pipe(
                    finalize(() => (this.isLoading = false)))
            .subscribe(resp => {
                this.model = resp;
                this.saveState(this.queryParams);
                this.loadFiles(isInsertEmmit);               
            });
    }

    loadFiles(isInsertEmmit: boolean = false) {
        let customParams = { ...this.queryParams, pageSize: 9999, searchTerm: null, page: 1 };
        //Files will be loaded only first time
        if (this.modelFiles && !isInsertEmmit) {
            this.content = { ...this.content, data: this.modelFiles };
            this.contentChange.emit(this.content);
            this.updateModelData(this.modelFiles);
        } else {
            this.projectTaskFileService.query(customParams)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe(res => {
                    this.modelFiles = res;
                    this.content = { ...this.content, data: res };
                    this.contentChange.emit(this.content);
                    this.updateModelData(res);
                });
        }
    }

    private updateModelData(files) {
        let items = this.model.data.map(c => {
            let found = files.data.find(b => b.uniqueId === c.fileUniqueId);
            return found ? { ...c, fileUrl: found.fileUrl, linkUrl: found.linkUrl } : { ...c };
        });

        this.model = {
            ...files,
            data: items,
            totalCount: this.model.totalCount,
            page: this.model.page,
            pageSize: this.model.pageSize
        };
    }  

    deleteById(presentationItemId: number) {
        this.isLoading = true;

        this.presentationItemService.deleteById(presentationItemId, this.firstProjectTaskId).pipe(
            finalize(() => this.isLoading = false))
            .subscribe((result) => {
                this.loadData();
            });
    }
}
