<af-page-filter [loading]="isLoading" [params]="queryParams" [hideButtons]="true">
    <div class="filter-group">
        <div class="form-group">
            <label class="control-label col-md-1">{{ 'hollen.clientapp.src.app.operation.report.report-employee.component.date' | translate }}</label>
            <div class="col-md-2">
                <af-datepicker [ngModel]="queryParams.date" (ngModelChange)="queryParams.date = $event; updateParams();"></af-datepicker>
            </div>
            <label class="control-label col-md-1">{{ 'hollen.clientapp.src.app.operation.report.report-employee.component.hollen company' | translate }}</label>
            <div class="col-md-2">
                <select class="form-control form-select" [(ngModel)]="queryParams.hollenCompanyCode" (ngModelChange)="queryParams.hollenCompanyCode = $event; updateParams();">
                    <option *ngFor="let hollenCompany of hollenCompanies" [ngValue]="hollenCompany.code">{{hollenCompany.title}}</option>
                </select>
            </div>
        </div>
    </div>
</af-page-filter>

<div class="row btn-select-box">
    <div class="col-sm-12">
        <button [ngClass]="{'btn-selected': selectedReport === 0}" class="btn btn-default mb-1" (click)="cancelFilter()">{{ 'hollen.clientapp.src.app.operation.report.report-employee.component.all' | translate }}</button>
        <ng-container *ngFor="let item of reports">
            <button [ngClass]="{'btn-selected': selectedReport === item.id}" (click)="selectReport(item.id)" class="btn btn-default mb-1">{{ item.title }}</button>
        </ng-container>
    </div>
</div>

<br />

<div class="row" [class.disabled-all]="!queryParams.employeeId">
    <ng-container *ngFor="let item of reports">
        <div class="col-md-4 mb-md-2" *ngIf="isVisible(item.id)">
            <op-report-employee-detail [params]="queryParams" [item]="item"></op-report-employee-detail>
        </div>
    </ng-container>
</div>
